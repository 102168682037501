/* You can add global styles to this file, and also import other style files */

.logo {
    max-width:198px;
}

html {
    scroll-behavior:smooth;
}

h5.event-time {
    margin-top:-5px !important;
}

.hero-graphic {
    max-height: 88vh;
    top: 83px;
    filter: drop-shadow(35px 23px 56px rgba(0, 0, 0, 0.3));
}

.service-img {
    max-width: 55px !important;
}

@media only screen and (max-width: 767px) {
    .mobile-hero-graphic {
        max-width:128px !important;
        display:initial !important;
        margin-bottom:32px;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-hero-graphic {
        display:none !important;
    }
}